import React, { useEffect, useRef, useState } from "react";
import styles from "./styles/Login.module.css";
import Form from "react-bootstrap/Form";
import { Alert } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import "../App.css";
import { json, useNavigate } from "react-router-dom";

const handleSuccess = (res) => {
  return {
    data: res.data,
    status: res.status,
  };
};

const handleError = (error) => {
  if (error.response === undefined) {
    return {
      data: { data: [error.message] },
      status: 500,
    };
  } else {
    return {
      data: error.response,
      status: error.response.status,
    };
  }
};

const loginLoad = async ({ email, password }) => {
  try {
    let res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/api/login",
      {
        email: email,
        password: password,
      },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_BACKEND_KEY,
        },
      }
    );
    return handleSuccess(res);
  } catch (error) {
    return handleError(error);
  }
};

const checkAuth = async ({ token, OTP }) => {
  try {
    let res = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        "/api/org/user-orgs" +
        (OTP ? "?otp_2fa=" + OTP : ""),
      {
        headers: {
          Authorization: "Bearer " + token,
          "x-api-key": process.env.REACT_APP_BACKEND_KEY,
        },
      }
    );
    return handleSuccess(res);
  } catch (error) {
    return handleError(error);
  }
};

const LoginPopUp = ({
  fnSetLoginState = () => {},
  fnSetUserData = () => {},
  loginState,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isFailed, setFailedState] = useState({ state: false, msg: "" });
  const [captcha, setCaptchaState] = useState(false);

  const email = useRef();
  const password = useRef();
  const otp = useRef();

  const navigate = useNavigate();

  const dummyLoad = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(false);
      }, 3000);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !email.current.value ||
      email.current.value === "" ||
      !password.current.value ||
      password.current.value === "" ||
      !captcha
    ) {
      setLoading(false);
      setFailedState({ state: true, msg: "Semua field wajb diisi" });
      setTimeout(() => {
        setFailedState({ state: false, msg: "Semua field wajb diisi" });
      }, 3000);
    } else {
      loginLoad({
        email: email.current.value,
        password: password.current.value,
      }).then((res) => {
        if (res.status === 200 && res.data.admin) {
          // redirect page
          console.log(res);
          localStorage.setItem("access_token", res.data.access_token);
          localStorage.setItem("login_state", "true");
          localStorage.setItem(
            "profile",
            JSON.stringify({
              email: res.data.data.email,
              name: res.data.data.name,
              photo: res.data.data.photo,
            })
          );
          fnSetUserData(res.data.data);
          checkAuth({ token: res.data.access_token }).then((res) => {
            setLoading(false);
            if (res.status === 200 || res.status === 404) {
              if (loginState === 2) {
                window.location.reload();
              }
              fnSetLoginState(1);
            } else if (res.status === 405) {
              fnSetLoginState(3);
              if (
                res.data.data.message !==
                "Mohon lakukan verifikasi kembali. Kode OTP telah dikirimkan ke email anda"
              ) {
                setFailedState({
                  state: true,
                  msg: Object.values(res.data.data).toString(),
                });
              } else {
                setFailedState({
                  state: false,
                  msg: Object.values(res.data.data).toString(),
                });
              }
            } else {
              setFailedState({
                state: true,
                msg: Object.values(res.data.data).toString(),
              });
            }
          });
          // if (loginState === 2) {
          //   window.location.reload();
          // }
          // fnSetLoginState(1);
        } else {
          setLoading(false);
          setFailedState({
            state: true,
            msg: Object.values(res.data.data).toString(),
          });
        }
      });
    }
  };

  const handleSubmitOtp = (e) => {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    checkAuth({
      token: localStorage.getItem("access_token"),
      OTP: e ? otp.current.value : null,
    }).then((res) => {
      setLoading(false);
      if (res.status === 200 || res.status === 404) {
        fnSetLoginState(1);
      } else if (res.status === 405) {
        fnSetLoginState(3);
        if (
          res.data.data.message !==
          "Mohon lakukan verifikasi kembali. Kode OTP telah dikirimkan ke email anda"
        ) {
          setFailedState({
            state: true,
            msg: Object.values(res.data.data).toString(),
          });
        } else {
          setFailedState({
            state: false,
            msg: Object.values(res.data.data).toString(),
          });
        }
      } else {
        if (res.status === 401) {
          fnSetLoginState(0);
        }
        setFailedState({
          state: true,
          msg: Object.values(res.data.data).toString(),
        });
      }
    });
  };

  return (
    <div className={`m-auto p-5 pt-2 rounded-3 bg-white`}>
      {/* {loginState === 0 ? ( */}
      {loginState === 3 ? (
        <Form onSubmit={handleSubmitOtp}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <div className="w-100 d-flex">
              <img
                className={`${styles.LogoForm}`}
                src="/images/logo.png"
                alt=""
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4 className="mb-4">Two Factor Auth</h4>
            {isFailed.state ? (
              <Alert variant="danger">{isFailed.msg}</Alert>
            ) : (
              <Alert variant="success">
                Kode OTP telah dikirim ke alamat email anda !
              </Alert>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>OTP Code</Form.Label>
            <Form.Control ref={otp} type="text" placeholder="Enter OTP Code" />
          </Form.Group>
          <div
            className="text-primary pointer m-auto mb-2 text-center"
            onClick={() => {
              handleSubmitOtp();
            }}
          >
            Tidak menerima OTP ? Kirim ulang
          </div>
          {isLoading ? (
            <button className="btn btn-primary w-100" disabled>
              <Spinner className={styles.LoadingBtnIcon} /> Loading ...
            </button>
          ) : (
            <button className="btn btn-primary w-100">Login</button>
          )}
        </Form>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <div className="w-100 d-flex">
              <img
                className={`${styles.LogoForm}`}
                src="/images/logo.png"
                alt=""
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <h4 className="mb-4">Login</h4>
            {isFailed.state ? (
              <Alert variant="danger">{isFailed.msg}</Alert>
            ) : (
              <></>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control ref={email} type="email" placeholder="Enter email" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              ref={password}
              type="password"
              placeholder="Password"
            />
          </Form.Group>

          <div className="d-flex">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              onChange={(value) => {
                setCaptchaState(value);
              }}
              className="mb-3 mx-auto"
            />
          </div>
          {isLoading ? (
            <button className="btn btn-primary w-100" disabled>
              <Spinner className={styles.LoadingBtnIcon} /> Loading ...
            </button>
          ) : (
            <button className="btn btn-primary w-100">Login</button>
          )}
        </Form>
      )}
    </div>
  );
};

export default LoginPopUp;
